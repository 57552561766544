import React, { useState } from 'react';
import {
    Box,
    Container,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Drawer,
    List,
    ListItem,
    ListItemText,
    Toolbar,
    Typography
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Link } from 'react-router-dom';
import { useAccount } from 'wagmi';
import { CustomConnectButton } from './CustomConnectButton';
import StyledButtonv2 from './StyledButtonv2';
import {AppProps} from "../Shared";

const EthereumIcon = 'assets/ethereum.svg'; // Add these icons to your assets
const SolanaIcon = 'assets/solana.svg';

const TopNavv2: React.FC<AppProps> = ({authStatus}) => {
    const { isConnected } = useAccount();
    const [isMobileNavOpen, setMobileNavOpen] = useState(false);

    // State for Chain Selector
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedChain, setSelectedChain] = useState('Ethereum');

    const toggleMobileNav = () => setMobileNavOpen(!isMobileNavOpen);
    const handleChainMenuClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
    const handleChainSelect = (chain: string) => {
        setSelectedChain(chain);
        setAnchorEl(null);

        // Redirect based on selection
        if (chain === 'Ethereum') {
            window.location.href = '#';
        } else if (chain === 'Solana') {
            window.location.href = '#';
        }
    };

    return (
        <Box sx={{ bgcolor: 'transparent', color: '#ffffff' }}>
            <Container maxWidth={false}>
                <Grid
                    item
                    xs={6}
                    md={2}
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        gap: 2
                    }}
                >
                    {/* Chain Selector */}
                    <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
                        <IconButton onClick={handleChainMenuClick} sx={{ color: '#ffffff' }}>
                            <img
                                src={selectedChain === 'Ethereum' ? EthereumIcon : SolanaIcon}
                                alt={selectedChain}
                                width={24}
                                height={24}
                                style={{ marginRight: '5px' }}
                            />
                            <ArrowDropDownIcon />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={() => setAnchorEl(null)}
                            sx={{
                                '& .MuiPaper-root': {
                                    bgcolor: '#0b0709',
                                    color: '#ffffff',
                                }
                            }}
                        >
                            <MenuItem onClick={() => handleChainSelect('Ethereum')} sx={{ fontWeight: '900' }}>
                                <img src={EthereumIcon} alt="Ethereum" width={20} style={{ marginRight: '10px' }} />
                                Ethereum
                            </MenuItem>
                            <MenuItem disabled sx={{ opacity: '0.75'}}>
                                <img src={SolanaIcon} alt="Solana" width={20} style={{ marginRight: '10px' }} />
                                Solana
                            </MenuItem>
                        </Menu>
                    </Box>

                    {/* Connect Button */}
                    <Box display="flex" alignItems="center" ml={2} sx={{ display: { xs: 'none', md: 'block' } }}>
                        <CustomConnectButton />
                    </Box>

                    {/* Mobile Menu Icon */}
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={toggleMobileNav}
                        sx={{ display: { xs: 'block', md: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                </Grid>
            </Container>
            {/* Mobile Navigation Drawer */}
            <Drawer
                anchor="right" // Opens from the right side
                open={isMobileNavOpen} // Controlled by state
                onClose={toggleMobileNav} // Close when clicking outside
            >
                <Box
                    sx={{
                        width: 250,
                        bgcolor: '#0b0709',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        padding: 2,
                        color: '#ffffff',
                    }}
                >
                    <List>
                        <ListItem component={Link} to="/" onClick={toggleMobileNav}>
                            <StyledButtonv2 to={"/"} style={{width: '100%', gap: 10, margin: '0px auto', background: 'none', boxShadow: 'none'}}>Home</StyledButtonv2>
                        </ListItem>
                        <ListItem component={Link} to="/my-locks" onClick={toggleMobileNav}>
                            <StyledButtonv2 to={"/my-locks"} style={{width: '100%', gap: 10, margin: '0px auto', background: 'none', boxShadow: 'none'}}>My Locks</StyledButtonv2>
                        </ListItem>
                        <ListItem component={Link} to="/new-lock" onClick={toggleMobileNav}>
                            <StyledButtonv2 to={"/new-lock"} style={{width: '100%', gap: 10, margin: '0px auto'}}>New Lock</StyledButtonv2>
                        </ListItem>
                    </List>

                    {/* Chain Selector */}
                    <Box sx={{ display: { xs: 'flex', md: 'none' }, alignItems: 'center', justifyContent: 'center' }}>
                        <IconButton onClick={handleChainMenuClick} sx={{ color: '#ffffff' }}>
                            <img
                                src={selectedChain === 'Ethereum' ? EthereumIcon : SolanaIcon}
                                alt={selectedChain}
                                width={24}
                                height={24}
                                style={{ marginRight: '5px' }}
                            />
                            <ArrowDropDownIcon />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={() => setAnchorEl(null)}
                            sx={{
                                '& .MuiPaper-root': {
                                    bgcolor: '#0b0709',
                                    color: '#ffffff',
                                }
                            }}
                        >
                            <MenuItem onClick={() => handleChainSelect('Ethereum')} sx={{ fontWeight: '900' }}>
                                <img src={EthereumIcon} alt="Ethereum" width={20} style={{ marginRight: '10px' }} />
                                Ethereum
                            </MenuItem>
                            <MenuItem disabled sx={{ opacity: '0.75'}}>
                                <img src={SolanaIcon} alt="Solana" width={20} style={{ marginRight: '10px' }} />
                                Solana
                            </MenuItem>
                        </Menu>
                    </Box>

                    {/* Mobile Connect Button */}
                    <Box mt="auto" pt={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                        {isConnected ? (
                            <CustomConnectButton/>
                        ) : (
                            <CustomConnectButton/>
                        )}
                    </Box>
                </Box>
            </Drawer>
        </Box>
    );
};

export default TopNavv2;
