import React, {useEffect, useState} from 'react';
import {
	Badge,
	Box,
	Button,
	ButtonProps,
	Drawer,
	Grid,
	IconButton,
	List,
	ListItem,
	Menu,
	MenuItem,
	styled
} from '@mui/material';
import {Link, useLocation} from 'react-router-dom';
import {useAccount} from 'wagmi';
import {AppProps} from "../Shared";
import {ConnectButton} from "@rainbow-me/rainbowkit";
import MenuIcon from "@mui/icons-material/Menu";
import RoofingRoundedIcon from '@mui/icons-material/RoofingRounded';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import {CustomConnectButton} from "./CustomConnectButton";
import StyledButtonv2 from "./StyledButtonv2";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const EthereumIcon = 'assets/ethereum.svg'; // Add these icons to your assets
const SolanaIcon = 'assets/solana.svg';

const StyledButton = styled((props: any & { component?: React.ElementType }) => (
	<Button {...props} />
))(({theme}) => ({
	// backgroundColor: '#2a053c',
	color: '#c4c4c4',
	borderRadius: '0px',
	textTransform: 'none',
	padding: '8px 16px',
	// '&:hover': {
	//   backgroundColor: '#343435',
	// },
	// fontWeight: 'bold',
	'&.Mui-disabled': {
		color: '#ffffff'
	}
}));

const StyledBadge = styled(Badge)(({theme}) => ({
	'& .MuiBadge-badge': {
		backgroundColor: '#fff',
		color: '#2a053c',
	},
}));

const TopNavLanding: React.FC<AppProps> = ({authStatus}) => {
	const {isConnected, address} = useAccount();
	const [locks, setLocks] = useState<number>(0);
	const location = useLocation();

	// State to track mobile nav open/close
	const [isMobileNavOpen, setMobileNavOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [selectedChain, setSelectedChain] = useState('Ethereum');

	const handleChainMenuClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
    const handleChainSelect = (chain: string) => {
        setSelectedChain(chain);
        setAnchorEl(null);

        // Redirect based on selection
        if (chain === 'Ethereum') {
            window.location.href = '#';
        } else if (chain === 'Solana') {
            window.location.href = '#';
        }
    };

	// Toggle mobile nav
	const toggleMobileNav = () => {
		setMobileNavOpen(!isMobileNavOpen);
	};

	const isActive = (path: string) => location.pathname === path;

	const fetchLocks = async () => {
		try {
			const response = await fetch(process.env.REACT_APP_API_URL + '/api/user/my-locks', {
				method: 'GET',
				credentials: 'include',
			});
			const data = await response.json();
			let locksCounter = 0;
			if (data.locks && data.locks.length) {
				data.locks.map((lock: any) => {
					// count locks with status 'unlockable'
					if (lock.status === 'unlockable') {
						locksCounter++;
					}
				});
			}
			setLocks(locksCounter);
		} catch (error) {
			console.error('Error fetching locks:', error);
		}
	};

	useEffect(() => {
		if (address && isConnected && authStatus === "authenticated") {
			fetchLocks();
		}
	}, [authStatus]);

	return (
		<>
			<Grid
				item
				xs={false} // Hidden on mobile
				md={7}
				sx={{
					display: {xs: "none", md: "flex"},
					justifyContent: "center",
				}}
			>
				<Box sx={{
					display: "flex",
					gap: {
						xs: '0',  // Default to 0px for small screens
						md: '0',  // For medium screens, set to 0px
						lg: '1', // For large screens, set to 10px
					},
					background: "rgba(255, 0, 122, 0.14)",
					border: "1px solid #1F1F1F",
					borderRadius: "32px",
					padding: "0px 0px",
					fontWeight: "bold"
				}}>
					<StyledButton
						component={Link}
						to="/"
						sx={{
							fontWeight: isActive("/") ? "bold" : "normal",
							color: isActive("/") ? "#fff" : "#c4c4c4",
							marginRight: {
								xs: '0px',  // Default to 0px for small screens
								md: '0px',  // For medium screens, set to 0px
								lg: '10px', // For large screens, set to 10px
							},
						}}>
						<RoofingRoundedIcon
							sx={{
								fontSize: "25px",
								color: "#ffffff",
								backgroundColor: "#212121",
								borderRadius: "50%",
								padding: "2px",
								marginRight: "8px"
							}}
						/>
						Home
					</StyledButton>
					<a href="#howitworks" style={{display: "flex"}}>
						<StyledButton
							// component={Link}
							// to="#howitworks"
							sx={{
								fontWeight: isActive("/howitworks") ? "bold" : "normal",
								marginRight: {
									xs: '0px',  // Default to 0px for small screens
									md: '0px',  // For medium screens, set to 0px
									lg: '10px', // For large screens, set to 10px
								},
							}}>How it works?</StyledButton>
					</a>
					{isConnected && (
						<StyledBadge badgeContent={locks}>
							<StyledButton component={Link} to="my-locks">My Locks</StyledButton>
						</StyledBadge>
					)}
					<StyledButton component={Link} to="new-lock"
								  style={{
									  color: isConnected ? '#ffffff' : 'rgb(152, 147, 156)',
									  fontWeight: isActive("/new-lock") ? "bold" : "normal",
									  marginRight: {
										  xs: '0px',  // Default to 0px for small screens
										  md: '0px',  // For medium screens, set to 0px
										  lg: '10px', // For large screens, set to 10px
									  },
								  }}
								  disabled={!isConnected}>
						<div style={{
							display: "flex", // Enable flexbox
							alignItems: "center", // Center items vertically
							border: "1px solid #1F1F1F",
							borderRadius: "32px",
							padding: "4px 16px 4px 8px",
							fontWeight: "bold",
							backgroundColor: "rgba(255, 0, 122, 0.1)"
						}}>
							<AddCircleOutlineRoundedIcon
								sx={{
									fontSize: "18px",
									color: isConnected ? '#ffffff' : 'rgb(152, 147, 156)',
									backgroundColor: "transparent",
									borderRadius: "50%",
									padding: "2px",
									marginRight: "4px"
								}}
							/>New Lock
						</div>
					</StyledButton>
				</Box>
			</Grid>

			{/* Right Column */
			}
			<Grid
				item
				xs={6}
				md={3}
				sx={{
					display: "flex",
					justifyContent: "flex-end",
				}}
			>
				{/* Chain Selector */}
				<Box sx={{display: {xs: 'none', md: 'flex'}, alignItems: 'center'}}>
					<IconButton onClick={handleChainMenuClick} sx={{color: '#ffffff'}}>
						<img
							src={selectedChain === 'Ethereum' ? EthereumIcon : SolanaIcon}
							alt={selectedChain}
							width={24}
							height={24}
							style={{marginRight: '5px'}}
						/>
						<ArrowDropDownIcon/>
					</IconButton>
					<Menu
						anchorEl={anchorEl}
						open={Boolean(anchorEl)}
						onClose={() => setAnchorEl(null)}
						sx={{
							'& .MuiPaper-root': {
								bgcolor: '#0b0709',
								color: '#ffffff',
							}
						}}
					>
						<MenuItem onClick={() => handleChainSelect('Ethereum')} sx={{fontWeight: '900'}}>
							<img src={EthereumIcon} alt="Ethereum" width={20} style={{marginRight: '10px'}}/>
							Ethereum
						</MenuItem>
						<MenuItem disabled sx={{opacity: '0.75'}}>
							<img src={SolanaIcon} alt="Solana" width={20} style={{marginRight: '10px'}}/>
							Solana
						</MenuItem>
					</Menu>
				</Box>

				<Box display="flex" alignItems="center" ml={2} sx={{display: {xs: "none", md: "block"}}}>
					{isConnected ? (
						// <CustomConnectButton showBalance={false} label="LOGOUT"/>
						<CustomConnectButton/>
					) : (
						// <CustomConnectButton label="SIGN IN"/>
						<CustomConnectButton/>
					)}
				</Box>

				{/* Show Burger Menu on Mobile */}
				<IconButton
					edge="end"
					color="inherit"
					onClick={toggleMobileNav} // Toggle the mobile nav
					sx={{display: {xs: "block", md: "none"}}}
				>
					<MenuIcon/>
				</IconButton>
			</Grid>
			{/* Mobile Navigation Drawer */}
			<Drawer
				anchor="right" // Opens from the right side
				open={isMobileNavOpen} // Controlled by state
				onClose={toggleMobileNav} // Close when clicking outside
			>
				<Box
					sx={{
						width: 250,
						bgcolor: '#0b0709',
						height: '100%',
						display: 'flex',
						flexDirection: 'column',
						padding: 2,
						color: '#ffffff',
					}}
				>
					<List>
						<ListItem component={Link} to="/" onClick={toggleMobileNav}>
							<StyledButtonv2 to={"/"} style={{
								width: '100%',
								gap: 10,
								margin: '0px auto',
								background: 'none',
								boxShadow: 'none'
							}}>Home</StyledButtonv2>
						</ListItem>
						<ListItem component={Link} to="/my-locks" onClick={toggleMobileNav}>
							<StyledButtonv2 to={"/my-locks"} style={{
								width: '100%',
								gap: 10,
								margin: '0px auto',
								background: 'none',
								boxShadow: 'none'
							}}>My Locks</StyledButtonv2>
						</ListItem>
						<ListItem component={Link} to="/new-lock" onClick={toggleMobileNav}>
							<StyledButtonv2 to={"/new-lock"} style={{width: '100%', gap: 10, margin: '0px auto'}}>New
								Lock</StyledButtonv2>
						</ListItem>
					</List>

					{/* Chain Selector */}
                    <Box sx={{ display: { xs: 'flex', md: 'none' }, alignItems: 'center', justifyContent: 'center' }}>
                        <IconButton onClick={handleChainMenuClick} sx={{ color: '#ffffff' }}>
                            <img
                                src={selectedChain === 'Ethereum' ? EthereumIcon : SolanaIcon}
                                alt={selectedChain}
                                width={24}
                                height={24}
                                style={{ marginRight: '5px' }}
                            />
                            <ArrowDropDownIcon />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={() => setAnchorEl(null)}
                            sx={{
                                '& .MuiPaper-root': {
                                    bgcolor: '#0b0709',
                                    color: '#ffffff',
                                }
                            }}
                        >
                            <MenuItem onClick={() => handleChainSelect('Ethereum')} sx={{ fontWeight: '900' }}>
                                <img src={EthereumIcon} alt="Ethereum" width={20} style={{ marginRight: '10px' }} />
                                Ethereum
                            </MenuItem>
                            <MenuItem disabled sx={{ opacity: '0.75'}}>
                                <img src={SolanaIcon} alt="Solana" width={20} style={{ marginRight: '10px' }} />
                                Solana
                            </MenuItem>
                        </Menu>
                    </Box>

					{/* Mobile Connect Button */}
					<Box mt="auto" pt={2}>
						{isConnected ? (
							<CustomConnectButton/>
						) : (
							<CustomConnectButton/>
						)}
					</Box>
				</Box>
			</Drawer>
		</>
	)
		;
};

export default TopNavLanding;
